@import 'base/css/styles/layout.scss';

.login-username-input {
    display:none;
}

.login-help-text {
    display: none;
}

.footer-support-code {
    display: none;
}

.login-password-input .help {
    display:none;
}

.forgot-password-md {
    a {
        display:none;
    }
}

.login-page .button-container {
    margin-top: 4rem;
}